import React from "react";

import Link from "../components/Global/Link";

export const calculateCrumbLink = (crumbIndex, breadCrumbs) => {
  if (crumbIndex === breadCrumbs.length - 1) {
    return;
  }

  let crumbUrl = "";
  for (let i = 0; i < breadCrumbs.length; i++) {
    if (i <= crumbIndex) {
      crumbUrl += `${breadCrumbs[i]}/`;
      continue;
    }

    break;
  }

  return crumbUrl;
};

export const breadCrumbsMap = (breadCrumbs, colour) => {
  return (
    breadCrumbs &&
    breadCrumbs?.map((crumb, index) => {
      if (index === breadCrumbs.length - 1) {
        return (
          <span
            key={index}
            className="brand-breadcrumb-item brand-breadcrumb-item-active"
          >
            {crumb}
          </span>
        );
      }
      return (
        <Link
          key={index}
          className={`brand-text-${colour} brand-breadcrumb-item me-3`}
          to={calculateCrumbLink(index, breadCrumbs)}
          icon
        >
          {crumb}
        </Link>
      );
    })
  );
};
