import React from "react";
import PropTypes from "prop-types";
import SmartColour from "../../utils/ColourHandler";
import { breadCrumbsMap } from "../../helpers/breadcrumbs";

const SectionBreadcrumb = (props) => {
  const { doc, index } = props;

  const colour = SmartColour(doc.colour);

  // Split the pathname and remove empty strings
  const pathNameSplit =
    typeof window !== "undefined" &&
    window?.location?.pathname.split("/").filter(Boolean);

  return (
    <section
      className={`w-100 brand-background-${colour.background} brand-text-${
        colour.text
      } ${index === 0 ? "brand-background-clip" : "py-5"}`}
    >
      <nav
        className="container py-5 brand-border-bottom"
        aria-label="breadcrumb"
      >
        <ol className="breadcrumb text-uppercase m-0">
          {breadCrumbsMap(pathNameSplit, colour.text)}
        </ol>
      </nav>
    </section>
  );
};

export default SectionBreadcrumb;

SectionBreadcrumb.propTypes = {
  doc: PropTypes.object,
};

SectionBreadcrumb.defaultProps = {
  doc: null,
};
